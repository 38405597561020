import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { textanimation } from "../Animation/index";
import PhoneInput from 'react-phone-number-input/input';
import Autocomplete, { usePlacesWidget } from 'react-google-autocomplete';
import Swal from 'sweetalert2';
import axios from 'axios';
// import BackButton from '../Button/BackButton';
import BackButton from '../Page4/Button/index';
import backButton from '../../../assets/backButton.svg';


type Props = {
	setopenComponent: React.Dispatch<React.SetStateAction<number>>;
	setEmail: React.Dispatch<React.SetStateAction<string>>;
	email: string;
	setName: React.Dispatch<React.SetStateAction<string>>;
	name: string;
	setContact: React.Dispatch<React.SetStateAction<string>>;
	contact: string;
	setCity: React.Dispatch<React.SetStateAction<string>>
	setPlace: React.Dispatch<React.SetStateAction<string>>
	setzipCode: React.Dispatch<React.SetStateAction<string>>
	setState: React.Dispatch<React.SetStateAction<string>>
	place: string;
	handleLandingPageData: () => Promise<void>
	backId: number
	buttonId?: string;
	state?: string,
};

const SecPageCompoSix = ({ setopenComponent, email, setEmail, handleLandingPageData, setName, name, setContact, contact, setPlace, setCity, setzipCode, setState, place, backId, buttonId, state}: Props) => {
	// const [email, setEmail] = useState("")
	const [fName, setFName] = useState("");
	const [errorFname, seterrorFname] = useState("");

	const [emailError, setEmailError] = useState("")
	const [Validemail, setValidEmail] = useState("")

	const [phone, setPhone] = useState('');
	const [phoneError, setPhoneError] = useState('');

	const [address, setAddress] = useState('');
	const [errorMsg, setErrorMsg] = useState('');

	//******Button Enable/Disable******/ 
	const [disable, setDisable] = useState(false);
	//******Button Enable/Disable******/ 


	useEffect(() => {
		setFName(name);
		setPhone(contact)
		setAddress(place)
	}, [])


	const inputs = document.querySelectorAll("input");

	// Add a keypress event listener to each input element
	inputs.forEach((input) => {
	  input.addEventListener("keypress", (e) => {
		// Check if the key code is 13 (enter key)
		if (e.keyCode === 13) {
		  // Prevent the default behavior of submitting the form
		  e.preventDefault();
		  // Get the current tab index of the input element
		  const currentTabIndex = input.tabIndex;
		  // Find the next input element with the next tab index
		  const nextInput = document.querySelector(
			`input[tabIndex="${currentTabIndex + 1}"]`
		  );
		  // If there is a next input element, focus on it
		  if (nextInput) {
	     (nextInput as HTMLInputElement).focus();
		  }
		}
	  });
	});


	const clickHandler = async () => {
         console.log("button pressed")
		if (!email || !fName || !address || !phone || phone.length < 12) {
			!fName && seterrorFname("Name is required")
			!address && setErrorMsg("Address is required")
			!email && setEmailError('Email is required')
			!phone && setPhoneError('Phone is required');
			return
		} else if (
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false
		) {
			return setValidEmail('Enter the valid email')
		}

		//  ***********************
		if (phone.length < 12) {
			return setPhoneError('Please enter valid phone number');
		}
		const resp = await checkPhoneIsCanadianValidOrNot();
		//****************
		if (resp === 200) {
			if (state) {
				if (state === 'ON') {
					handleLandingPageData()
					document.getElementById("view")?.scrollIntoView()
					setopenComponent(9);
					return;
				}
				setErrorMsg("Enter Ontario Address")
			}
			else {
				handleLandingPageData()
				document.getElementById("view")?.scrollIntoView()
				setopenComponent(9);
			}
		}
	}

	const checkPhoneIsCanadianValidOrNot = async () => {
		const formData = new FormData();
		formData.append('contact_no', contact);

		console.log("formData", formData)
		try {
			const { data } = await axios({
				method: 'post',
				url: 'https://nu2morrow.com/crm/apis/SecUser/contactvalidator',
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' }
			});

			// console.log(formData);
			// console.log(data);
			if (data?.status !== 200) {
				return alert('Invalid number');
			}
			return data?.status;
			// console.log(data?.data);
		} catch (err) {
			//   const invalidError = err.message;
			//   console.log(err.message);
			return Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Number is not valid!',
				// footer: '<a href="">Why do I have this issue?</a>'
			});
			//   setValidPhone(invalidError)
		}
	};

	const changefunction = (e: any) => {
		setPhone(e);
		setContact(e)
		// console.log(phone) 
		setPhoneError('');
	};

	const chkAddressFieldEmpyOrField = (e: any) => {
		// console.log(e.target.value)
		setAddress("")
		setErrorMsg("")
	}

	const handleSelect = async (value: any) => {
		// console.log(value)
		// const results = await geocodeByAddress(value)
		// const ll = await getLatLng(results[0])

		// setCoordinate(ll)

		let address_country = '';
		let postal_code = '';
		let address_state = '';
		let address_city = '';
		let place = '';
        let full_address = '';
		value?.address_components?.forEach((item: any) => {
			const countryFound = item.types.find((e: any) => e === 'country');
			const postalFound = item.types.find((e: any) => e === 'postal_code');
			const placeFound = item.types.find((e: any) => e === 'route');
			const stateFound = item.types.find((e: any) => e === 'administrative_area_level_1');
			const cityFound = item.types.find((e: any) => e === 'locality');
			full_address = item;
			if (countryFound) {
				address_country = item.short_name;
			}
			if (postalFound) {
				postal_code = item.long_name;
			}
			if (stateFound) {
				address_state = item.short_name;
			}
			if (cityFound) {
				address_city = item.long_name;
			}
			if (placeFound) {
				place = item.long_name;
			}
			// console.log("full places : ", item);
		});
		console.log("full address :", full_address ?? "")
		console.log("address_country :", address_country ?? "")
		console.log("postal_code :", postal_code ?? "")
		console.log("address_state :", address_state ?? "")
		console.log("address_city :", address_city ?? "")
		console.log("place :", place ?? "")

		// ****************
		setState(address_state)
		setzipCode(postal_code)
		setCity(address_city)
		setPlace(`${place}, ${address_city}, ${address_state}, ${postal_code}`)
	};
	// *********onClick*********

	return (
		<>

			<div onClick={() => { setopenComponent(backId) }}>
				<img src={backButton} alt="" className="w-10 cursor-pointer" />
			</div>
			<div className="bg-mainCard">
				<div className="pt-20">
					<motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
						<p className="font-raleway mx-2 sm:mx-0 text-3xl md:text-4xl lg:text-5xl xl:text-7xl text-center font-black">
							We can’t wait to get started
							on your reports!
						</p>
					</motion.div>
					<motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
						<p className="font-sans font-bold text-sm sm:text-base md:text-xl lg:text-2xl xl:text-3xl text-center text-[#737373] mt-10">We also hate SPAM, your data will be kept confidential.</p>
					</motion.div>
				</div>
				{/* name input */}
				<div className="flex flex-col justify-center items-center mt-10">

					<div className="bg-white w-4/5 md:w-2/5 lg:w-2/6 rounded-full border-2 my-2">
						<input
							type="text"
							pattern="[a-zA-z]+"
							value={name && name}
							placeholder="Your Name"
							tabIndex={1}
							required
							onChange={(e) => {
								if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
									//   setEmailE(e.target.value)
									setName(e.target.value)
									setFName(e.target.value)
									seterrorFname('')
								}
							}}
							className="outline-none w-full rounded-full px-8 font-sans text-base pl-4 md:px-16 py-2"
						/>
					</div>

					<span className="text-red-700 text-center sm:w-[65%] mx-auto  text-[10px] sm:text-[12px] font-semibold">
						{errorFname}
					</span>
					{/* email input */}
					<div className="bg-white w-4/5 md:w-2/5 lg:w-2/6 rounded-full border-2">
						<input
							type="email"
							value={email && email}
							placeholder="example@gmail.com"
							required
							tabIndex={2}
							onChange={(e) => {
								//   setEmailE(e.target.value)
								setEmail(e.target.value)
								setEmailError('')
								setValidEmail('')
							}}
							className="outline-none w-full rounded-full px-8 font-sans text-base pl-4 md:px-16 py-2"
						/>
					</div>
					<span className="text-red-700 text-center sm:w-[65%] mx-auto text-[10px] sm:text-[12px] font-semibold">
						{emailError ? emailError : Validemail ? Validemail : null}
					</span>

					{/* phone input */}
					{/* <div className="flex flex-col justify-center items-center bg-red-200 "> */}
					<div  className="bg-white w-4/5 md:w-2/5 lg:w-2/6 rounded-full border-2 my-2">
						<PhoneInput
							className="outline-none w-full rounded-full px-8 font-sans text-base pl-4 md:px-16 py-2"
							placeholder="Your Phone"
							maxlength="14"
							tabIndex={3}
							country="US"
							value={contact && contact}
							onChange={changefunction}
						// onChange={(e) => {
						// 	setPhone(e?.target.value);
						// 	setContact(e)
						// 	console.log(contact)
						// 	setPhoneError('');
						// }}
						/>
					</div>
					<div className="text-red-700 text-center sm:w-[65%] mx-auto text-[10px] sm:text-[12px] font-semibold">
						{phoneError}
					</div>
					{/* </div>

					{/* address input */}
					<div  className="bg-white rounded-full border-2 w-4/5 md:w-2/5 lg:w-2/6 ">
						<Autocomplete
							apiKey="AIzaSyDKhj1caiJVMeNgBAqbOjV97q0oMyAuRiQ"
							// style={{ width: '90%' }}
							tabIndex={4}
							className="outline-none rounded-full font-sans text-base px-4 md:px-16 py-2 w-full"
							// value={address}
							onPlaceSelected={(place) => {
								handleSelect(place);
								setAddress(place?.formatted_address);
							}}

							placeholder="Your Home Address"
							options={{
								types: ['geocode', 'establishment'],
								componentRestrictions: { country: 'ca' }
							}}
							// defaultValue={address}
							defaultValue={place && place}
							onChange={chkAddressFieldEmpyOrField}
						// onSelect={handleSelect}
						/>


					</div>
					<span className="text-red-700 text-center sm:w-[65%] mx-auto  text-[10px] sm:text-[12px] font-semibold">{errorMsg}</span>

					{/* checkbox */}
					<div className="py-8 w-11/12 sm:w-3/4 md:w-1/2">
						<input
							onClick={() => { disable === true ? setDisable(false) : setDisable(true) }}
							type="checkbox" id="id1" name="id1" value="" className="w-5 h-5 accent-brand" />
						<label htmlFor="id1" className="font-sans font-base text-sm sm:text-base md:text-xl text-center mt-10 ml-4">
							By clicking this button, you agree to and acknowledge
							Nuborrow's Terms of Use and <a href='https://nuborrow.com/privacy-policy/' className='text-brand font-semibold'>Privacy Policy.</a>
						</label>
					</div>


					{/* button */}
					<div className="mt-5 mb-20">
						<button
							id={buttonId}
							// disabled
							onClick={() => {console.log("correct press");  disable ? clickHandler() : null }}
							// {!disable}
							//   
							className={disable ? `bg-brand rounded-full font-sans text-lg font-bold text-white px-8 py-3 sm:px-16 sm:py-4 mt-8 hover:bg-white hover:text-brand hover:border-brand hover:border-2` : `bg-gray-300 rounded-full font-sans text-lg font-bold text-white px-8 py-3 sm:px-16 sm:py-4 mt-8 cursor:arrow`}>Get your reports</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default SecPageCompoSix;