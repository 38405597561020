import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { textanimation } from "../Animation/index";
import backButton from "../../../assets/back.svg"
import Card from './Card';
import axios from 'axios';

function index() {
    const [HomeEquityLoanRate, setHomeEquityLoanRate] = useState<number>();
    const [HomeEquityLineRate, setHomeEquityLineRate] = useState<number>();
    useEffect(() => {
        fetchRates('Equity Loan');
        fetchRates('Equity Line');
    }, []);
    async function fetchRates(name: string | undefined) {
        try {
            const res = await axios({
                method: 'get',
                url: 'https://nu2morrow.com/crm/apis/products/rates'
            });
            const apiData = res.data.rates;
            const bankRate =
                apiData && apiData.find((item: { name: string }) => item.name === name)?.banks;
            if (name === 'Equity Loan') {
                return setHomeEquityLoanRate(bankRate?.length > 0 && bankRate[0]?.bank_rate);
            }
            if (name === 'Equity Line') {
                return setHomeEquityLineRate(bankRate?.length > 0 && bankRate[0]?.bank_rate);
            }
        } catch (err) {
            console.log(err);

        }
    }


    return (
        <div className="pt-20 bg-white">
            <motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
                <p className="font-raleway mx-2 sm:mx-0 mb-16 text-3xl md:text-4xl lg:text-5xl xl:text-7xl text-center font-black">
                    Today’s <span className='text-brand' >Best</span> Home Equity Rates
                </p>
            </motion.div>
            <div className='flex flex-col items-center md:flex-row md:justify-center md:gap-10'>
                <Card heading='Home equity line of credit' value={HomeEquityLineRate} />
                <Card heading='Home equity loan' value={HomeEquityLoanRate} />
            </div>
        </div>
    )
}

export default index